import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { modalsShadow } from '../../../styleHelpers/mixins/shadow';
import { EToolTipTheme, ToolTip } from './ToolTip';

const Wrapper = styled.div<{ directionBottom?: boolean, fixedWidth?: string, withIco?: boolean }>`
    position: relative;
    z-index: 9;
    ${props => props.fixedWidth && css`
        width: ${`${props.fixedWidth}px`};
    `}
    svg {
        color: ${colorStack.darkGrey};
    }
    > div {
        cursor: pointer;
        margin: 0 .5rem 0 .5rem;
        & + div {
            visibility: hidden;
        };
        &:hover + div {
            visibility: visible;
            display: inline-table;
            ${props => props.directionBottom && css`
                top:0;
                bottom: auto;
            `};
        };
        ${props => props.withIco && css`
            margin: 0 0 0 .5rem;
            height: 16px;
            line-height: 0;
        `}
    };
`;

const TooltipBox = styled.div`
    position: absolute;
    width: 300px;
    left: -142px;
    top: 30px;
    display: flex;
    justify-content: center;
    > div {
        width: 300px;
        position: relative;
        white-space: pre-wrap;
    }
`;

const WrapperWithoutIco = styled.div<{ fixedWidth: string }>`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 999;
    ${props => props.fixedWidth && css`
        width: ${`${props.fixedWidth}px`};
    `}
    > div {
        display: none;
        position: relative;
        white-space: nowrap;
        ${props => props.fixedWidth && css`
            left: ${`calc(${Number(props.fixedWidth) * -1}px - 2px)`};
        `}
    }
    &:hover {
        > div {
            display: inline-table;
        }
    }
`;

const Content = styled.div<{ colorsSchema: 'dark' | 'light', width: number }>`
    color: ${colorStack.white};
    background: ${colorStack.label};
    position: absolute;
    top: calc(100% + 16px);
    ${fontSizeAndHeight[13]};
    ${modalsShadow};
    border-radius: 8px;
    z-index: 999999;
    padding: .5rem 1rem;
    width: auto;
    white-space: nowrap;
    z-index: 1;
    ${props => props.width && css`
        left: ${`calc(50% - ${props.width / 2}px)`};
    `}
    ${props => props.colorsSchema === 'light' && css`
        color: ${colorStack.content};
        background: ${colorStack.white};
    `}
`;

const Triangle = styled.div<{ colorsSchema: 'dark' | 'light' }>`
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 16px solid transparent;
    border-right: 14px solid transparent;
    border-left: 14px solid transparent;
    border-bottom: 16px solid ${colorStack.label};
    bottom: 100%;
    width: 16px;
    ${props => props.colorsSchema === 'light' && css`
        border-bottom: 16px solid ${colorStack.white};
    `}
`;

interface Props {
    withIco?: boolean;
    fixedWidth?: string;
    description?: string;
    colorsSchema?: EToolTipTheme;
    content?: string;
    title: string;
}

export const ToolTipWrapper: FC<Props> = props => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [coords, setCoords] = useState<number[]>([]);
    const [tooltipWidth, setTooltipWidth] = useState<number>(0);

    const showTooltipHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        setCoords([e.pageX - (tooltipWidth / 2), e.pageY + 30]);
        setShowTooltip(true);
    };

    return (
        <>
            {props.withIco ? (
                <Wrapper withIco onMouseMove={showTooltipHandler} onMouseOver={showTooltipHandler} onMouseLeave={() => setShowTooltip(false)}>
                    <div>
                        <FontAwesomeIcon icon={faCircleInfo} />
                    </div>
                    <TooltipBox>
                        {showTooltip &&
                            <ToolTip
                                theme={props.colorsSchema}
                                fixedWidth={props.fixedWidth}
                                content={props.content}
                                title={props.title}
                                left={coords[0]}
                                setTooltipWidth={setTooltipWidth}
                                top={coords[1]}
                            />
                        }
                    </TooltipBox>
                </Wrapper>
            ) : (
                <WrapperWithoutIco fixedWidth={props.fixedWidth} onMouseMove={showTooltipHandler} onMouseOver={showTooltipHandler} onMouseLeave={() => setShowTooltip(false)}>
                    {showTooltip &&
                        <ToolTip
                            theme={props.colorsSchema}
                            content={props.content}
                            title={props.title}
                            fixedWidth={props.fixedWidth}
                            left={coords[0]}
                            setTooltipWidth={setTooltipWidth}
                            top={coords[1]}
                        />
                    }
                </WrapperWithoutIco>
            )}
        </>
    );
};
