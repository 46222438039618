import { ValidationResponse } from '../../entities/IValidation';

export const SET_APPROVAL_OVERVIEW_INFORMATIONS = 'SET_APPROVAL_OVERVIEW_INFORMATIONS';
export const CLEAR_APPROVAL_OVERVIEW_INFORMATIONS = 'CLEAR_APPROVAL_OVERVIEW_INFORMATIONS';
export const LOCK_APPROVAL_OVERVIEW = 'LOCK_APPROVAL_OVERVIEW';
export const UNLOCK_APPROVAL_OVERVIEW = 'UNLOCK_APPROVAL_OVERVIEW';

export interface IApprovalTypes {
    SET_APPROVAL_OVERVIEW_INFORMATIONS: {
        singleValidation: ValidationResponse
    }
}