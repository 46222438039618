import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';

import { EProfileType } from '../../../../entities/IGlobal';
import { Avatar } from '../../Avatar/Avatar';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';
import { modalsShadow } from '../../../../styleHelpers/mixins/shadow';

const Wrapper = styled.div`
    display: flex;
    width: 192px;
`;

const WrapperLink = styled(Link)`
    display: flex;
    max-width: calc(100% - 29px);
    &:hover { text-decoration: none; }
`;

const NumberOfItems = styled.span`
    ${fontSizeAndHeight[16]};
    color: ${colorStack.middleBlue};
    font-weight: 700;
    margin-left: .5rem;
`;

const More = styled.div`
    ${fontSizeAndHeight[16]};
    color: ${colorStack.middleBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    > span {
        font-weight: 700;
    }
    &:hover {
        > div {
            display: flex;
        }
    }
`;

const InnerTooltip = styled.div`
    background: ${colorStack.white};
    border-radius: 8px;
    ${modalsShadow()};
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    flex-wrap: wrap;
    width: calc(100% - 40px);
    display: flex;
`;

const Tooltip = styled.div`
    position: absolute;
    display: none;
    top: 40%;
    z-index: 10;
    right: 0;
    width: 700px;
`;

const LeftMockMargin = styled.div`
    width: 40px;
    height: 100%;
`;

export enum EPeoleContext {
    ORGANIZATION = 'ORGANIZATION',
    CLUSTER = 'CLUSTER',
    GROUP = 'GROUP',
    PANEL = 'PANEL'
}
export interface IMember {
    id: string;
    picture: string;
    firstName: string;
    lastName: string;
    type: EProfileType;
}
interface IProps {
    members: IMember[];
    organizationUrlName?: string;
    id?: string;
    context: EPeoleContext;
    linkPanel?: string;
}

export const People: FC<React.PropsWithChildren<IProps>> = props => {
    const [link, setLink] = useState<string>('');

    useEffect(() => {
        if (props.context === EPeoleContext.CLUSTER) {
            setLink(`/orgs/${props.organizationUrlName}/cluster/${props.id}/members`);
        }
        if (props.context === EPeoleContext.ORGANIZATION) {
            setLink(`/orgs/${props.organizationUrlName}/settings#Members`);
        }
        if (props.context === EPeoleContext.GROUP) {
            setLink(`/groups/${props.id}`);
        }
        if (props.context === EPeoleContext.PANEL) {
            setLink(props.linkPanel);
        }
    }, [props.context]);

    return (
        <>
            {props.members?.length > 0 ? (
                <Wrapper>
                    <WrapperLink to={link}>
                        <Avatar
                            id={props.members[0].id}
                            firstName={props.members[0].firstName}
                            lastName={props.members[0].lastName}
                            picture={props.members[0].picture}
                            extended
                            noTooltip
                        />
                    </WrapperLink>
                    {props.members?.length > 1 &&
                        <More>
                            <NumberOfItems>+{props.members?.length - 1}</NumberOfItems>
                            <Tooltip>
                                <LeftMockMargin />
                                <InnerTooltip>
                                    <Scrollbars autoHide autoHeight autoHeightMax={130}>
                                        {props.members?.map(elem => (
                                            <>
                                                <Avatar
                                                    id={elem.id}
                                                    firstName={elem.firstName}
                                                    lastName={elem.lastName}
                                                    picture={elem.picture}
                                                    extended
                                                    noTooltip
                                                />
                                                <br />
                                            </>
                                        ))}
                                    </Scrollbars>
                                </InnerTooltip>
                            </Tooltip>
                        </More>
                    }
                </Wrapper>
                // tslint:disable-next-line:no-null-keyword
            ) : null}
        </>
    );
};