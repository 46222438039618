import { EClusterStatus, IAddressGlobal, IReferencial, IUserBrief } from './IGlobal';

export interface IItem {
    text: string;
    id: string;
    visible: boolean;
    data: any;
    field?: string;
    translationKey: string;
}

export interface ISearchResults {
    items: IItemResults[];
    facets: {[key: string]: any};
    count: number;
    itemsOnPageCount: number;
    page: number;
    size: number;
}
export enum EFacetsTypes {
    SimpleStringValueFacet = 'SimpleStringValueFacet',
    CreatorFacet = 'CreatorFacet',
    ClusterSearchViewClusterTemplateDto = 'ClusterSearchViewClusterTemplateDto',
    ReferentialDto = 'ReferentialDto',
    ClusterSearchViewLegalEntityDto = 'ClusterSearchViewLegalEntityDto',
    MineFacetDto = 'MineFacetDto',
    OrganizationTypesFacetDto = 'OrganizationTypesFacetDto',
    ClusterSearchViewOrganizationDto = 'ClusterSearchViewOrganizationDto',
    UserDto = 'UserDto',
    StatusFacetDto = 'StatusFacetDto',
    LegalEntityFacetDto = 'LegalEntityFacetDto',
    ReferentialDataDto = 'ReferentialDataDto'
}

export type IFacet =
    | {
        facetType: EFacetsTypes.SimpleStringValueFacet;
        value: string;
    }[]
    | {
        facetType: EFacetsTypes.CreatorFacet | EFacetsTypes.ClusterSearchViewLegalEntityDto | EFacetsTypes.UserDto | EFacetsTypes.LegalEntityFacetDto;
        id: string;
        name: string;
        picture: string;
    }[]
    | {
        facetType: EFacetsTypes.ClusterSearchViewClusterTemplateDto;
        id: string;
        name: string;
        color: string;
        key: string;
    }[]
    | {
        facetType: EFacetsTypes.ReferentialDto;
        id: string;
        key: string;
        name: string;
    }[]
    | {
        facetType: EFacetsTypes.MineFacetDto | EFacetsTypes.OrganizationTypesFacetDto | EFacetsTypes.StatusFacetDto;
        key: string;
        name: string;
    }[]
    | {
        facetType: EFacetsTypes.ClusterSearchViewOrganizationDto;
        id: string;
        name: string;
        urlName: string;
    }[]
    | {
        facetType: EFacetsTypes.ReferentialDataDto;
        Key: string;
        logicalId: string;
        id: string;
        name: string;
        referentialIdentityId: string;
    }[];

export interface IItemResults {
    picture: string;
    address: IAddressGlobal;
    clusterId: string;
    clusterTemplate: {
        color: string;
        id: string;
        key: string;
        name: string;
    };
    firstReferential: IReferencial[];
    id: string;
    lastModified: {
        date: string;
        user: {
            id: string;
            name: string;
            picture: string;
        };
    };
    legalEntities: any[];
    name: string;
    numberOfDocuments: number;
    numberOfMessages: number;
    organization: {
        id: string;
        name: string;
        urlName: string;
    };
    members: {
        id: string;
        name: string;
        picture: string;
    }[];
    privacy: string;
    secondReferential: IReferencial[];
    status: EClusterStatus;
    thirdReferential: IReferencial[];
}

export interface IProfileClusters {
    count: number;
    facets: {[key: string]: any};
    items: {
        'cluster-overview': {
            businessId: number;
            clusterId: string;
            clusterName: string;
            status: {
                value: string;
                translationKey: string;
                enumElement: string;
            };
            clusterTemplateName: string;
            organizationUrlName: string;
            organizationId: string;
            organizationName: string;
        };
        'cluster-people': {
            id: string;
            name: string;
            picture: string;
            facetType: string;
        }[]
        'cluster-template-type': string;
        'last-edited-by': {
            date: string;
            user: IUserBrief;
        }
        'number-of-documents': {
            hyperlinkParameters: {
                organizationUrlName: string;
                id: string;
            };
            routeName: string;
            text: string;
        }
        'number-of-messages': {
            hyperlinkParameters: {
                organizationUrlName: string;
                id: string;
            };
            routeName: string;
            text: string;
        }
    }[]
}