import React, { useEffect, useState, useCallback, FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { useParams } from 'react-router-dom';

import { colorStack } from '../../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../../styleHelpers/fontSizes';
import { exportDatesAsICS } from '../../../../../../actions/datesActions';
import { getReferentials } from '../../../../../../actions/globalActions';
import { Button, EButtonTypeSchema } from '../../../../../Common/Buttons/NewButton';
import { TextComponent } from '../../../../../Common/Inputs/TextComponent';
import { IReferencial, EProfileType } from '../../../../../../entities/IGlobal';
import { IDates, EDatesStatus, EDatesTypes, ERecuringType } from '../../../../../../entities/IDates';
import { IStakeConfiguration } from '../../../../../../entities/IClusterStake';
import { IState } from '../../../../../../reducers';
import { IClusterReducer } from '../../../../../../reducers/clustersReducer';
import { inputsShadow } from '../../../../../../styleHelpers/mixins/shadow';
import { LabelWithEllipsis } from '../../../../../Common/Label/Label';
import { DatePickerWrapper } from '../../../../../Common/DatePicker/DatePicker';
import { OnOff } from '../../../../../Common/Buttons/OnOff';
import { SelectInput } from '../../../../../Common/SelectInput/SelectInput';
import { getDaysOfMonth, translatedDaysNames, translatedMonthNames } from '../../../../../../tools/date';
import { TextArea } from '../../../../../Common/Inputs/TextArea';
import { EDropDownType } from '../../../../../Common/DropDown/DropDown';
import { IValue } from '../../../../../../entities/IPickers';
import { StaticDataPicker } from '../../../../../Common/Pickers/StaticDataPicker';
import IntlMessage, { useIntlMessage } from '../../../../../Common/IntlMessage';
import { getClusterMembers } from '../../../../../../actions/clustersActions';

type GetReferentials = ReturnType<typeof getReferentials>;
type ExportDatesAsICS = ReturnType<typeof exportDatesAsICS>;
type GetClusterMembers = ReturnType<typeof getClusterMembers>;

const Wrapper = styled.div`
    width: 100%;
`;

const DateSelectBox = styled.div`
    padding: 1rem;
    margin: 0 0 1rem 0;
    background-color: ${colorStack.white};
    border-radius: 4px;
`;

const MainBoxHeader = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    > div {
        width: 250px;
    }
    p {
        text-align: left;
        margin: 0;
        font-size: ${fontSize[20]};
    }
    ${props => props.active && css`
        span, svg {
            color: ${colorStack.middleBlue};
            stroke: ${colorStack.middleBlue};
        }
    `}
`;

const MainBoxItems = styled.div`
    width: 100%;
`;

const BoxItem = styled.div<{ isFilled?: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    > div, > textarea {
        flex: 3;
        max-width: 75%;
        ${props => props.isFilled && css`
            border-bottom: 1px solid ${colorStack.darkRed};
        `}
    }
    .ms-TextField input {
        border: none;
        ${inputsShadow()};
        ${props => props.isFilled && css`
            border-bottom: 1px solid ${colorStack.darkRed};
        `}
    }
`;

const Status = styled.div`
    color: ${colorStack.label};
    font-size: ${fontSize[13]};
`;

const TwoBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 20px;
`;
const SingleBox = styled.div`
    width: 48%;
    padding: 1rem;
    background-color: ${colorStack.whiteGrey};
    border-radius: 8px;
`;

const Reminder = styled.div<{ TextColor: string }>`
    display: flex;
    cursor: pointer;
    align-items: center;
    font-size: ${fontSize[13]};
    margin-bottom: 10px;
    ${props => props.TextColor && css`
        color: ${props.TextColor};
    `}
    svg {
        width: 50px!important;
        margin-right: 1rem;
    }
`;
const Days = styled.div<{ TextColor: string }>`
    display: flex;
    cursor: pointer;
    align-items: center;
    font-size: ${fontSize[13]};
    margin-bottom: 10px;
    ${props => props.TextColor && css`
        color: ${props.TextColor};
    `}
`;

const InputWrapper = styled.div`
    width: 70px;
    margin-right: 1rem;
`;

const DateBoxItem = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    position: relative;
    left: -1rem;
    width: calc(100% + 2rem);
    padding: 1rem;
    background: ${colorStack.white};
    transition: all .3s;
    ${props => props.active && css`
        background: ${colorStack.whiteBlue};
    `}
`;

const DateBox = styled.div`
    display: flex;
    align-items: flex-end;
    > div:first-child {
        width: 250px;
        margin: 0 2rem 0 0;
    };
`;

const DaysName = styled.div`
    margin: 0 0 1rem 0;
`;

const MonthBox = styled.div`
    display: flex;
    width: 50%;
    gap: 1rem;
    align-self: flex-end;
    align-items: center;
    > span {
        display: block;
        margin: 0 .5rem 0 0;
    }
    > div {
        width: 60px;
    }
`;

const YearBox = styled.div`
    display: flex;
    width: 50%;
    align-self: flex-end;
    align-items: center;
    > span {
        display: block;
        margin: 0 .5rem 0 0;
    }
    > div {
        &:nth-child(1) {
            width: 60px;
            margin: 0 .5rem 0 0;
        }
        &:nth-child(2) {
            width: 180px;
        }
    }
`;

const DayNameItem = styled.span<{ active: boolean }>`
    width: 24px;
    height: 24px;
    cursor: pointer;
    font-size: ${fontSize[16]};
    border-radius: 100%;
    display: inline-flex;
    color: ${colorStack.content};
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: all .3s;
    ${props => props.active && css`
        background: ${colorStack.middleBlue};
    `};
`;

const RepeatBox = styled.div`
    margin: 0 2rem 0 0;
    > div {
        width: 250px;
        display: flex;
        justify-content: space-between;
        > div {
            width: 72%;
            display: flex;
            align-items: center;
            &:first-child {
                width: 25%;
                margin-right: .5rem;
            }
        }
    }
`;

const ChangeBox = styled.div`
    display: flex;
    padding: 1rem 0;
`;

enum ERecuringTypes {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Year = 'Year'
}

interface IProps {
    currentDate: IDates;
    configuration: IStakeConfiguration;
    stakeFamilyKey?: string;
    stakeListKey?: string;
    hardTitle?: boolean;
    isValid(valid: boolean);
    getDataFromPopup?(currentDate: IDates);
}

export const DatesPopup: FC<React.PropsWithChildren<IProps>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const [titles, setTitles] = useState<IReferencial[]>(undefined);
    const [isReminderValid, setIsReminderValid] = useState<boolean>(false);
    const [isPropertyFilled, setIsPropertyFilled] = useState({ isActorEmpty: false });
    const [currentDate, setCurrentDate] = useState<IDates>(props.currentDate || {
        actors: [],
        description: '',
        isReminderActivated: false,
        // tslint:disable-next-line:no-null-keyword
        reminder: null,
        // tslint:disable-next-line:no-null-keyword
        endAt: null,
        // tslint:disable-next-line:no-null-keyword
        startAt: null,
        // tslint:disable-next-line:no-null-keyword
        status: null,
        // tslint:disable-next-line:no-null-keyword
        title: null,
        // tslint:disable-next-line:no-null-keyword
        type: null,
        // tslint:disable-next-line:no-null-keyword
        recuring: null,
        // tslint:disable-next-line:no-null-keyword
        nextOccurrenceDate: null
    });

    const repeatSelectOption = [
        intlFormatMessage({ id: 'global.day' }),
        intlFormatMessage({ id: 'global.week' }),
        intlFormatMessage({ id: 'global.month' }),
        intlFormatMessage({ id: 'global.year' })
    ];

    const colors = useMemo(() => ({
        [EDatesStatus.InProgress]: colorStack.middleBlue,
        [EDatesStatus.NotStarted]: colorStack.black,
        [EDatesStatus.Done]: colorStack.lightGreen,
        [EDatesStatus.Overdue]: colorStack.red
    }), []);

    const statuses: IValue[] = [{
        key: EDatesStatus.NotStarted,
        text: `dates.status.${EDatesStatus.NotStarted}`,
        data: {
            id: EDatesStatus.NotStarted,
            color: colors[EDatesStatus.NotStarted]
        }
    }, {
        key: EDatesStatus.InProgress,
        text: `dates.status.${EDatesStatus.InProgress}`,
        data: {
            id: EDatesStatus.InProgress,
            color: colors[EDatesStatus.InProgress]
        }
    }, {
        key: EDatesStatus.Done,
        text: `dates.status.${EDatesStatus.Done}`,
        data: {
            id: EDatesStatus.Done,
            color: colors[EDatesStatus.Done]
        }
    }, {
        key: EDatesStatus.Overdue,
        text: `dates.status.${EDatesStatus.Overdue}`,
        data: {
            id: EDatesStatus.Overdue,
            color: colors[EDatesStatus.Overdue]
        }
    }];

    const { currentClusterId, currentClusterData } = useSelector<IState, IClusterReducer>(state => state.clusters);

    useEffect(() => {
        if (props.hardTitle) {
            dispatch<GetReferentials>(getReferentials('', props.configuration.titleReferentialIdentityType, props.configuration.titleReferentialIdentityContext)).then(response => {
                setTitles(response);
            });
        }
        setCurrentDate(props.currentDate);
    }, []);

    useEffect(() => {
        titles?.length > 0 && setCurrentDate(state => ({ ...state, title: titles?.[0] }));
    }, [titles]);

    const changeActors = (items: IValue[]) => {
        setCurrentDate(state => ({
            ...state,
            actors: items?.map(elem => ({
                firstName: elem.data?.childName,
                lastName: '',
                cabinet: '',
                jobTitle: '',
                picture: elem.data?.picture,
                id: elem.data?.id,
                emailContact: '',
                type: elem.data?.type

            }))
        }));
        setIsPropertyFilled(state => ({
            ...state,
            isActorEmpty: !items
        }));
    };

    const isRecuringTypeValid = useCallback((type: string) => {
        if (type === ERecuringTypes.Day) {
            return true;
        }
        if (type === ERecuringTypes.Week && currentDate?.recuring?.frequency?.daysOfWeek?.length > 0) {
            return true;
        }
        if (type === ERecuringTypes.Month && currentDate?.recuring?.frequency?.dayOfMonth) {
            return true;
        }
        if (type === ERecuringTypes.Year && currentDate?.recuring?.frequency?.dayOfMonth && currentDate?.recuring?.frequency?.month) {
            return true;
        }
        return false;
    }, [currentDate]);

    useEffect(() => {
        if (((currentDate?.title?.name || currentDate?.recuring?.frequency?.endDate) && (currentDate?.isReminderActivated ? currentDate?.actors?.length !== 0 : true) &&
            (currentDate?.recuring ? (currentDate?.recuring?.frequency?.startDate && isRecuringTypeValid(currentDate?.recuring?.frequency?.type) && currentDate?.recuring?.frequency?.number && currentDate?.title?.name) : true))) {
            props.isValid(true);
            props.getDataFromPopup && props.getDataFromPopup(currentDate);
        } else {
            props.isValid(false);
        }
        if (currentDate?.actors?.length > 0 && currentDate?.title?.name && (currentDate?.endAt || currentDate?.recuring?.frequency?.endDate && currentDate?.recuring?.frequency?.startDate)) {
            setIsReminderValid(true);
        } else {
            setIsReminderValid(false);
        }
    }, [currentDate]);

    useEffect(() => {
        if (!currentDate?.recuring?.frequency ? (currentDate?.actors?.length < 1 || !currentDate?.title?.name || !currentDate?.endAt)
            : (currentDate?.actors?.length < 1 || !currentDate?.title?.name || !currentDate?.recuring?.frequency?.startDate || !currentDate?.recuring?.frequency?.endDate)) {
            return setCurrentDate(state => ({ ...state, isReminderActivated: false, reminder: { remindBeforeDays: 0, remindEachDays: 0 } }));
        }
    }, [currentDate?.actors, currentDate?.endAt, currentDate?.recuring?.frequency?.endDate, currentDate?.recuring?.frequency?.startDate, currentDate?.title?.name]);

    const changeStartDate = (date: Date) => {
        // tslint:disable-next-line:no-null-keyword
        const changeHour = date ? new Date(new Date(date).setHours(7)).toUTCString() : null;
        if (currentDate?.recuring) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring,
                    frequency: {
                        ...state.recuring.frequency,
                        startDate: changeHour
                    }
                }
            }));
        } else {
            setCurrentDate(state => ({ ...state, endAt: changeHour }));
        }
    };

    const changeEndDate = (endAt: Date) => {
        // tslint:disable-next-line:no-null-keyword
        const changeHour = endAt ? new Date(new Date(endAt).setHours(7)).toUTCString() : null;
        if (currentDate?.recuring) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring,
                    frequency: {
                        ...state.recuring.frequency,
                        endDate: changeHour
                    }
                }
            }));
        } else {
            setCurrentDate(state => ({ ...state, endAt: changeHour }));
        }
    };

    const dateInputHandler = (value: string) => {
        // tslint:disable-next-line:no-null-keyword
        setCurrentDate(state => ({ ...state, reminder: { remindBeforeDays: Number(value), remindEachDays: null } }));
    };

    const onExport = () => {
        if (currentDate?.id) {
            dispatch<ExportDatesAsICS>(exportDatesAsICS(currentClusterId, currentDate.id, currentClusterData.businessId.value, currentClusterData?.presentation?.name, window.location.href));
        }
    };

    const notesHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        setCurrentDate(state => ({ ...state, description: text }));
    };

    const changeStatus = (item: IValue) => {
        setCurrentDate(state => ({ ...state, status: item.key as EDatesStatus }));
    };

    const changeTitle = (value: string) => {
        setCurrentDate(state => ({
            ...state, title: ({
                // tslint:disable-next-line:no-null-keyword
                Key: null,
                // tslint:disable-next-line:no-null-keyword
                id: null,
                // tslint:disable-next-line:no-null-keyword
                logicalId: null,
                name: value
            })
        }));
    };

    const changeReminderStatus = () => {
        isReminderValid &&
            setCurrentDate(state => ({ ...state, isReminderActivated: !state.isReminderActivated, reminder: { remindBeforeDays: 0, remindEachDays: 0 } }));
        setIsPropertyFilled(state => ({
            ...state,
            isActorEmpty: !currentDate?.actors?.length
        }));
    };

    const onOffHandler = (stateButton: boolean) => {
        setCurrentDate(state => ({
            ...state,
            recuring: stateButton ? ({
                frequency: {
                    // tslint:disable-next-line:no-null-keyword
                    type: ERecuringType.Day,
                    // tslint:disable-next-line:no-null-keyword
                    number: null,
                    // tslint:disable-next-line:no-null-keyword
                    startDate: state.endAt,
                    // tslint:disable-next-line:no-null-keyword
                    endDate: null,
                    // tslint:disable-next-line:no-null-keyword
                    daysOfWeek: null,
                    // tslint:disable-next-line:no-null-keyword
                    dayOfMonth: null,
                    // tslint:disable-next-line:no-null-keyword
                    month: null
                }
                // tslint:disable-next-line:no-null-keyword
            }) : null
        }));
    };

    const changeNumberOfRepeat = (value: string) => {
        if (Number(value) === 0 || !Number(value) || isNaN(Number(value))) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        // tslint:disable-next-line:no-null-keyword
                        number: null
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === ERecuringType.Day && (Number(value) < 8 && Number(value) > 0)) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        number: Number(value)
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === ERecuringType.Week && (Number(value) < 53 && Number(value) > 0)) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        number: Number(value)
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === ERecuringType.Month && (Number(value) < 13 && Number(value) > 0)) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        number: Number(value)
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === ERecuringType.Year && Number(value) > 0) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        number: Number(value)
                    }
                }
            }));
        }
    };

    const changeRepeatOption = (option?: IValue) => {
        // currentDate?.recuring?.frequency?.type
        setCurrentDate(state => ({
            ...state, recuring: {
                ...state.recuring, frequency: option ? {
                    ...state.recuring.frequency,
                    type: option?.key as ERecuringType,
                    // tslint:disable-next-line:no-null-keyword
                    dayOfMonth: null,
                    // tslint:disable-next-line:no-null-keyword
                    number: null,
                    // tslint:disable-next-line:no-null-keyword
                    daysOfWeek: null,
                    month: 1
                    // tslint:disable-next-line:no-null-keyword
                } : null
            }
        }));
    };

    const selectDayOfWeek = (day: number) => {
        setCurrentDate(state => ({
            ...state, recuring: {
                ...state.recuring, frequency: {
                    ...state.recuring.frequency,
                    daysOfWeek: [day]
                }
            }
        }));
    };

    const changeOnDay = (value: string) => {
        if (Number(value) === 0 || !Number(value) || isNaN(Number(value))) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        // tslint:disable-next-line:no-null-keyword
                        dayOfMonth: null
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === ERecuringType.Month && Number(value) < 32 && Number(value) > 0) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        dayOfMonth: Number(value)
                    }
                }
            }));
        }
        if (currentDate?.recuring?.frequency?.type === ERecuringType.Year && Number(value) < getDaysOfMonth[currentDate?.recuring?.frequency?.month - 1].length + 1 && Number(value) > 0) {
            setCurrentDate(state => ({
                ...state, recuring: {
                    ...state.recuring, frequency: {
                        ...state.recuring.frequency,
                        dayOfMonth: Number(value)
                    }
                }
            }));
        }
    };

    const changeMonth = (option?: IValue) => {
        setCurrentDate(state => ({
            ...state, recuring: {
                ...state.recuring, frequency: {
                    ...state.recuring.frequency,
                    month: option?.data
                }
            }
        }));
    };

    const resolvePeople = async (query: string = ''): Promise<IValue[]> => {
        const members = await dispatch<GetClusterMembers>(getClusterMembers(id, query));
        return members.map(elem => ({ key: elem.id, text: elem.childName, data: { ...elem, type: EProfileType.Personal } }));
    };

    const checkIsActive = () => {
        if (!!(currentDate?.id && (currentDate?.endAt || (currentDate?.recuring?.frequency?.endDate && currentDate?.recuring?.frequency?.startDate)))) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Wrapper>
            <DateSelectBox>
                <MainBoxHeader active={checkIsActive()}>
                    {(props.hardTitle || (props.currentDate && (props.currentDate?.type && props.currentDate?.type?.name !== EDatesTypes.KeyDate))) ? (
                        <span>{currentDate?.title?.name || titles?.[0].name}</span>
                    ) : (
                        <div>
                            <LabelWithEllipsis>
                                <IntlMessage id="global.title" />
                            </LabelWithEllipsis>
                            <TextComponent onChange={changeTitle} value={currentDate?.title?.name} />
                        </div>
                    )}
                    <Button type="button" leftIco={faCalendarAlt} typeSchema={EButtonTypeSchema.TEXT} onClick={onExport} disabled={!currentDate?.id || !currentDate?.endAt}>
                        <IntlMessage id="dates.button.sync" />
                    </Button>
                </MainBoxHeader>
                <MainBoxItems>
                    <DateBoxItem active={!!currentDate?.recuring}>
                        <DateBox>
                            {!currentDate?.recuring &&
                                <DatePickerWrapper
                                    value={currentDate?.endAt ? new Date(currentDate?.endAt) : undefined}
                                    onChange={changeStartDate}
                                    label={<IntlMessage id="dates.label.startDate" />}
                                />
                            }
                            {currentDate?.recuring &&
                                <DatePickerWrapper
                                    value={currentDate?.recuring?.frequency?.startDate ? new Date(currentDate?.recuring?.frequency?.startDate) : undefined}
                                    onChange={changeStartDate}
                                    label={<IntlMessage id="dates.label.startDate" />}
                                />
                            }
                            <OnOff getCurrentState={onOffHandler} state={!!currentDate?.recuring} data-lc="js-lc-button-dates-periodicity" />
                        </DateBox>
                        {currentDate?.recuring &&
                            <div>
                                <ChangeBox>
                                    <RepeatBox>
                                        <LabelWithEllipsis>
                                            <IntlMessage id="dates.label.repeatEvery" />
                                        </LabelWithEllipsis>
                                        <div>
                                            <TextComponent value={currentDate?.recuring?.frequency?.number?.toString()} onChange={changeNumberOfRepeat} />
                                            <SelectInput
                                                value={[{ key: currentDate?.recuring?.frequency?.type, text: currentDate?.recuring?.frequency?.type, data: currentDate?.recuring?.frequency?.type }]}
                                                onChange={changeRepeatOption}
                                                options={repeatSelectOption?.map((elem, index) => ({
                                                    key: Object.keys(ERecuringType)[index],
                                                    text: elem,
                                                    data: {
                                                        elem
                                                    }
                                                }))}
                                                type={EDropDownType.DEFAULT}
                                            />
                                        </div>
                                    </RepeatBox>
                                    {currentDate?.recuring?.frequency?.type === ERecuringType.Month &&
                                        <MonthBox>
                                            <IntlMessage id="dates.label.onDay" />
                                            <TextComponent value={currentDate?.recuring?.frequency?.dayOfMonth?.toString()} onChange={changeOnDay} />
                                        </MonthBox>
                                    }
                                    {currentDate?.recuring?.frequency?.type === ERecuringType.Year &&
                                        <YearBox>
                                            <IntlMessage id="dates.label.on" />
                                            <TextComponent value={currentDate.recuring.frequency?.dayOfMonth?.toString()} onChange={changeOnDay} />
                                            <SelectInput
                                                value={currentDate.recuring.frequency?.month ? [{ key: currentDate.recuring.frequency?.month?.toString(), text: intlFormatMessage({ id: `global.monthName.${currentDate.recuring.frequency?.month}` }), data: currentDate.recuring.frequency?.month }] : undefined}
                                                onChange={changeMonth}
                                                options={translatedMonthNames(intlFormatMessage)}
                                                type={EDropDownType.SEARCH}
                                            />
                                        </YearBox>
                                    }
                                </ChangeBox>
                                {currentDate?.recuring?.frequency?.type === ERecuringType.Week &&
                                    <DaysName>
                                        {translatedDaysNames(intlFormatMessage).map((elem, index) =>
                                            <DayNameItem key={elem.key} active={currentDate.recuring.frequency?.daysOfWeek?.[0] === Number(elem.key)} onClick={() => selectDayOfWeek(Number(elem.key))}>
                                                {elem.text[0]}
                                            </DayNameItem>
                                        )}
                                    </DaysName>
                                }
                                <DatePickerWrapper
                                    value={currentDate?.recuring?.frequency?.endDate ? new Date(currentDate?.recuring?.frequency?.endDate) : undefined}
                                    onChange={changeEndDate}
                                    label={<IntlMessage id="dates.label.endDate" />}
                                />
                            </div>
                        }
                    </DateBoxItem>
                    <BoxItem>
                        <LabelWithEllipsis><IntlMessage id="dates.label.actors" /></LabelWithEllipsis>
                        <StaticDataPicker
                            multiSelect
                            errorMsg={isPropertyFilled.isActorEmpty ? intlFormatMessage({ id: 'dates.fillActors' }) : ''}
                            getData={resolvePeople}
                            onSelectElementMulti={changeActors}
                            value={(currentDate?.actors || [])?.map(actor => ({
                                key: actor.id,
                                text: `${actor.firstName} ${actor.lastName}`,
                                data: { ...actor, type: EProfileType.Personal }
                            }))}
                        />
                    </BoxItem>
                    <BoxItem>
                        <TextArea
                            label={intlFormatMessage({ id: 'dates.label.notes' })}
                            onChange={notesHandler}
                            value={currentDate?.description}
                        />
                    </BoxItem>

                </MainBoxItems>
            </DateSelectBox>
            <TwoBox>
                <SingleBox>
                    <Status><IntlMessage id="dates.label.status" /></Status>
                    <SelectInput
                        options={statuses}
                        onChange={changeStatus}
                        value={currentDate?.status ? [{ key: currentDate?.status, text: intlFormatMessage({ id: `dates.status.${currentDate?.status}` }), data: { color: colors[currentDate?.status] } }] : undefined}
                        type={EDropDownType.STATUS}
                        openDropdownToTop
                    />
                </SingleBox>
                <SingleBox>
                    <Reminder onClick={changeReminderStatus} TextColor={currentDate?.isReminderActivated ? colorStack.middleBlue : (isReminderValid ? colorStack.label : colorStack.middleGrey)} data-lc="js-lc-button-dates-reminder">
                        <FontAwesomeIcon icon={faBell} size="lg" />
                        <IntlMessage id="dates.label.setReminder" />
                    </Reminder>
                    <Days TextColor={currentDate?.isReminderActivated ? colorStack.middleBlue : colorStack.middleGrey}>
                        <InputWrapper>
                            <TextComponent disabled={!currentDate?.isReminderActivated} onChange={dateInputHandler} value={currentDate?.reminder?.remindBeforeDays?.toString()} />
                        </InputWrapper>
                        <IntlMessage id="dates.label.daysBefore" />
                    </Days>
                </SingleBox>
            </TwoBox>
        </Wrapper>
    );
};

export default DatesPopup;
