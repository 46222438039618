import { Dispatch } from 'redux';

import { action, IDispatch } from '../tools/reduxTools';
import { EReferentials, ISearchResponse, IUserBrief, IReferencial, EContextList } from '../entities/IGlobal';
import { IReferentialsIdentities } from '../entities/IClusters';
import { EProfileDataType } from '../tools/profileConstants';
import { ISearchReferentialElements } from '../entities/IProfile';
import { IGlobalTypes, SET_REFERENTIAL, SET_PROGRESS_DATA } from './actionTypes/globalTypes';
import { IState } from '../reducers';
import { Http } from '../tools/http';
import { ELcid } from '../entities/ILanguage';
import { addData, EDBNames, getStoreDataByKey, initDB, Stores } from '../tools/db';
import { removeArrDuplicates } from '../tools/arrayTools';

interface IDbReferential { id: string, key: string, nameLabels: { label: string, lcid: ELcid }[], parentClientReferentialIds: [] }

export const getReferentialsChildren = (query: string = '', referentialIdentity: string, parent?: string) => action<Promise<IReferencial[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiGet(`/referentials/elements/${referentialIdentity}/children?size=9999&lcid=${userLanguage}${query.length > 0 ? `&query=${query}` : ''}${parent ? `&parent=${parent}` : ''}`);
});

export const getAllReferentials = () => action<Promise<IReferencial[]>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const status = await initDB(EDBNames.REFERENTIALSDB);
    return http.profilesApiGet(`/referentials/identities-with-elements`, undefined, undefined, undefined, Stores.ALLREFERENTIALS).then(async (res) => {
        const referentialsIdsArr = res.map(elem => elem.referentialElements).flat();
        for (let i = 0; i < referentialsIdsArr.length; i++) {
            await addData(Stores.ALLREFERENTIALSIDS, referentialsIdsArr[i], referentialsIdsArr[i].id, EDBNames.REFERENTIALSDB);
        }
        const contexts: string[] = removeArrDuplicates(res.map(elem => elem.context));
        if (status && contexts.length > 0) {
            for (let i = 0; i < contexts.length; i++) {
                const filteredData = res.filter(elem => elem.context === contexts[i]);
                for (let y = 0; y < filteredData.length; y++) {
                    const finalData = Object.fromEntries(filteredData.map(el => [el.referentialType, el]));
                    if (finalData) {
                        await addData(Stores.ALLREFERENTIALS, finalData, contexts[i], EDBNames.REFERENTIALSDB);
                    }
                }
            }
        }
    });
});

const referentialsCachedPromises: { [key: string]: Promise<IReferencial[]> } = {};
export const getReferentials = (query: string = '', type: EProfileDataType | EReferentials, context: EContextList = EContextList.Data, parent?: string, bustCache?: boolean) => action<Promise<IReferencial[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    const { referentials } = getState().global;
    const promiseId = context + type + userLanguage + (parent || '');

    if (referentialsCachedPromises[promiseId]) {
        return referentialsCachedPromises[promiseId];
    }

    const cachedPromise = new Promise<IReferencial[]>((resolve) => {
        getStoreDataByKey(Stores.ALLREFERENTIALS, context).then((res: IDbReferential[]) => {
            if (res?.[type]?.referentialElements?.length > 0) {
                const filteredData = parent ? res[type].referentialElements.filter(element => element?.parentClientReferentialIds?.includes(parent)) : res[type].referentialElements;
                resolve(filteredData?.map((elem: IDbReferential) => ({
                    Key: elem.key,
                    id: elem.id,
                    // tslint:disable-next-line:no-null-keyword
                    logicalId: null,
                    name: elem.nameLabels.filter(label => label.lcid === userLanguage)?.[0]?.label || '',
                    parents: elem.parentClientReferentialIds
                })).sort((a, b) => a.name.localeCompare(b.name)));
            } else {
                let obj = referentials || {};
                let isExistContext = !!obj?.[context];
                let isExistType = !!obj?.[context]?.[type];
                if (!isExistContext || !isExistType || parent || bustCache) {
                    return http.profilesApiGet(`/referentials/contexts/${context}/types/${type}/elements?size=9999&lcid=${userLanguage}${query.length > 0 ? `&query=${query}` : ''}${parent ? `&parent=${parent}` : ''}`).then((response: ISearchReferentialElements) => {
                        if (isExistContext) {
                            if (!isExistType) {
                                obj = { ...obj, [context]: { ...obj[context], [type]: response.items } };
                            }
                        } else {
                            obj = { ...obj, [context]: { [type]: response.items } };
                        }

                        dispatch<IDispatch<IGlobalTypes['SET_REFERENTIAL']>>({
                            type: SET_REFERENTIAL,
                            referentialsList: obj
                        });

                        resolve(response.items);
                        delete referentialsCachedPromises[promiseId];

                        return response.items;
                    });
                }
                if (referentials?.[context]?.[type]) {
                    resolve(referentials?.[context]?.[type]?.filter(elem => elem?.name?.toLowerCase()?.includes(query?.toLowerCase())));
                } else {
                    resolve([]);
                }

                delete referentialsCachedPromises[promiseId];
            }
        });
    });

    referentialsCachedPromises[promiseId] = cachedPromise;

    return cachedPromise;
});

export const getUnits = () => action<Promise<ISearchReferentialElements>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiGet(`/referentials/units?size=9999&lcid=${userLanguage}`);
});

export const getReferentialsIdentities = (query: string = '') => action<Promise<IReferentialsIdentities[]>>((dispatch, getState, http) => {
    return http.profilesApiGet(`/referentials/identities?size=9999${query.length > 0 ? `&query=${query}` : ''}`).then((res: ISearchResponse<IReferentialsIdentities>) => res.items);
});

export const getAllClusterReferentials = (clusterTemplateId: string) => action<Promise<{ identityContext: EContextList; identityId: string; identityType: EProfileDataType | EReferentials; }[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    const { referentials } = getState().global;
    return http.clustersApiGet(`/cluster-templates/${clusterTemplateId}/referentials?lcid=${userLanguage}`).then(response => {
        let obj = referentials || {};
        for (let i = 0; i < response.length; i++) {
            let isExistContext = !!obj?.[response[i].identityContext];
            if (isExistContext) {
                let isExistType = !!obj?.[response[i].identityContext]?.[response[i].identityType];
                if (!isExistType) {
                    obj = { ...obj, [response[i].identityContext]: { ...obj[response[i].identityContext], [response[i].identityType]: response[i].elements } };
                }
            } else {
                obj = { ...obj, [response[i].identityContext]: { [response[i].identityType]: response[i].elements } };
            }

        }
        dispatch<IDispatch<IGlobalTypes['SET_REFERENTIAL']>>({
            type: SET_REFERENTIAL,
            referentialsList: obj
        });
    });
});

export const getMultipleUsersById = (body: string[]) => action<Promise<IUserBrief[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiPost(`/users/by-ids?lcid=${userLanguage}`, body);
});

export const getReferentialsByUniqueKey = (uniqueKey: string) => action<Promise<{ name: { label: string, lcid: ELcid }[] }>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const selectedReferential: IDbReferential = await getStoreDataByKey(Stores.ALLREFERENTIALSIDS, uniqueKey);
    if (selectedReferential) {
        return {
            children: [],
            id: selectedReferential.id,
            key: selectedReferential.key,
            name: selectedReferential.nameLabels,
            parents: selectedReferential.parentClientReferentialIds
        };
    }
    return http.profilesApiGet(`/referentials/elements/${uniqueKey}`);

});

export const setProgressData = (progressData: number) => ({
    type: SET_PROGRESS_DATA,
    progressData
});
