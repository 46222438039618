import React, { FC, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight, FontSizeKeys } from '../../../styleHelpers/fontSizes';
import { modalsShadow } from '../../../styleHelpers/mixins/shadow';
import { ToolTip } from '../ToolTip/ToolTip';

const Wrapper = styled.div`
    position: relative;
    > div {
        display: none;
        position: absolute;
        padding: 10px;
        border-radius: 4px;
        font-size: ${fontSize[16]};
        ${modalsShadow()};
        background: ${colorStack.white};
        color: ${colorStack.content};
    }
    &:hover {
        > div {
            display: block;
            z-index: 99;
        }
    }
`;

const Text = styled.span<{ numberOfLines: number, fontSize: number }>`
    width: 100%;
    display: block;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    ${props => props.fontSize && css`
        ${fontSizeAndHeight[props.fontSize]};
    `}
    line-height: 1.1rem;
    ${props => props.numberOfLines > 1 && css`
        display: -webkit-box !important;
        white-space: normal;
        -webkit-line-clamp: ${props.numberOfLines};
    `}
`;

interface IProps {
    numberOfLines: number;
    noTooltip?: boolean;
    fontSize?: FontSizeKeys;
}

export const MultilineTextWithEllipsis: FC<React.PropsWithChildren<IProps>> = props => {
    const textRef = useRef<HTMLDivElement>();
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [coords, setCoords] = useState<number[]>([]);

    const showTooltipHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        const element = textRef?.current;
        setCoords([e.pageX - textRef?.current?.offsetWidth, e.pageY + 30 ]);
        const isTextClamped = element.scrollHeight > element.clientHeight;
        const isTextTruncated = element.scrollWidth > element.clientWidth;
        setShowTooltip(isTextClamped || isTextTruncated);
    };

    return (
        <Wrapper>
            <Text
                numberOfLines={props.numberOfLines}
                ref={textRef}
                fontSize={props.fontSize}
                onMouseMove={props.noTooltip ? undefined : showTooltipHandler}
                onMouseOver={props.noTooltip ? undefined : showTooltipHandler}
                onMouseLeave={() => setShowTooltip(false)}
            >
                {props.children}
            </Text>
            {showTooltip &&
                <ToolTip
                    title={`${props.children}`}
                    left={coords[0]}
                    top={coords[1]}
                />
            }
        </Wrapper>
    );
};
