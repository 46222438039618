import { Dispatch } from 'redux';
import queryString from 'query-string-for-all';

import { IState } from '../reducers';
import { Http } from '../tools/http';
import * as actionTypes from './actionTypes/legalEntitiesTypes';
import { action, downloadFile } from '../tools/reduxTools';
import { IPLEGridSettings, ISIngleLegalEntity, IShortSingleLegalEntity, ISinglePLEDocument } from '../entities/ILegalEntities';
import { IDataGridSettings } from '../entities/IClusters';
import { IReferencial } from '../entities/IGlobal';
import { IGridSourceRow } from '../tools/dataGridTools';
import { ELcid } from '../entities/ILanguage';
import { ISearchResults } from '../entities/IFilters';
import { formDataEncodeAndAppendFile } from '../tools/files';

export const getAllLegalEntities = (query: string, filters?: { [key: string]: string[] }, top?: number, IsNotAssignedToCluster?: boolean, orgId?: string) => action<Promise<ISearchResults>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const body = {
        Query: query || '',
        ...filters,
        AssociatedClusterOrganizationId: orgId,
        IsNotAssignedToCluster,
        IsNotAssociatedToCluster: orgId ? true : false
    };
    return http.profilesApiPost(`/legal-entities/search-brief${top ? `?top=${top}` : ''}`, body, undefined, 15000).then((results: ISearchResults) => {
        dispatch({
            type: actionTypes.GET_LEGAL_ENTITIES,
            legalEntitiesResult: {
                list: results.items,
                facets: results.facets,
                count: results.count
            }
        });
        return results;
    });
});

export const getAllDirectoryLegalEntities = (query: string, filters?: { [key: string]: string[] }, skip?: number, top?: number, IsNotAssignedToCluster?: boolean) => action<Promise<ISearchResults>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const body = {
        Query: query || '',
        ...filters,
        IsNotAssignedToCluster
    };
    return http.profilesApiPost(`/legal-entities/search${top ? `?top=${top}&skip=${skip || 0}` : ''}`, body, undefined, 15000).then((results: ISearchResults) => {
        dispatch({
            type: actionTypes.GET_LEGAL_ENTITIES,
            legalEntitiesResult: {
                list: results?.items,
                facets: results?.facets,
                count: results?.count
            }
        });
        return results;
    });
});

export const clearResults = () => ({
    type: actionTypes.CLEAR_RESULTS
});

export const getCompanyById = (legalEntityId: string) => action<Promise<ISIngleLegalEntity>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.profilesApiGet(`/legal-entities/${legalEntityId}?lcid=${language.userLanguage || ELcid.Fr}`);
});

export const newUpdateCompany = (body: ISIngleLegalEntity | IShortSingleLegalEntity, companyId?: string) => action<Promise<ISIngleLegalEntity>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    if (companyId) {
        return http.profilesApiPut(`/legal-entities`, body);
    } else {
        return http.profilesApiPost(`/legal-entities`, body);
    }
});

export const getAllSizes = () => action<Promise<{ Name: string; id: string; }[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/GetAllSizes`);
});

export const getGridSettings = () => action<Promise<IDataGridSettings>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/companies/data-grid`);
});

export const setGridSettings = (body: IDataGridSettings) => action<Promise<{ Name: string; id: string; }[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiPost(`/companies/data-grid`, body);
});

export const uploadCompanyLogo = (userEmail: string, file: Blob | File) => action<Promise<string>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const formData = new FormData();

    if (file instanceof File) {
        formDataEncodeAndAppendFile(formData, 'fileImg', file);
    } else {
        formData.append('fileImg', file);
    }

    return http.cmsApiPost('/UploadImage?userEmailAddress=' + encodeURIComponent(userEmail), formData)
        .then((data: string[]) => {
            // tslint:disable-next-line:no-null-keyword
            return data && data.length > 0 ? data[0] : null;
        });
});

export const getTypeOfActivity = () => action<Promise<IReferencial[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/type-of-activity`);
});
export const getLegalForm = () => action<Promise<IReferencial[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/legal-form`);
});
export const getAliasType = () => action<Promise<IReferencial[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/client-referentials/types/AliasType`).then(response => {
        return response.Items;
    });
});
export const getCurrencies = () => action<Promise<IReferencial[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/currencies`);
});
export const getTypeOfLegalEntity = () => action<Promise<IReferencial[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.apiGet(`/type-of-legal-entity`);
});

export const sendFiles = (files: FormData, legalEntityId: string, documentLabel: string) => action<Promise<ISinglePLEDocument>>((dispatch, getState, http) => {
    return http.profilesApiPost(documentLabel  ? `/legal-entities/${legalEntityId}/documents/upload?documentLabel=${documentLabel}` : `/legal-entities/${legalEntityId}/documents/upload`, files);
});

export const downloadDocument = (legalEntityId: string, documentId: string) => action<Promise<ISinglePLEDocument>>((dispatch, getState, http) => {
    const body = {
        DocumentId: documentId
    };
    return http.profilesApiPost(`/legal-entities/${legalEntityId}/documents`, body);
});

export const downloadPdfEntity = (entityId: string, entityName: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { context, config } = getState();
    const { userLanguage } = getState().language;
    const getDate = new Date();
    const query = queryString.stringify({
        fileType: 'pdf',
        exportMode: 'pdf',
        export: true,
        lcid: userLanguage
    });
    const url = encodeURIComponent(`/company/${entityId}/export-entity?${query}`);
    await downloadFile(
        `${config.clustersApiUrl}/webpage-export?fileType=pdf&timeZone=${timeZone}exportMode=pdf&url=${url}`,
        `${entityName} ${getDate.toLocaleDateString()}.pdf`,
        context.token,
        config.subKey
    );
});

export const getPLEGridData = () => action<Promise<IGridSourceRow[]>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiGet(`/legal-entities/datagrid/data?${userLanguage}`);
});

export const getPLEGridSettings = () => action<Promise<IPLEGridSettings>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiGet(`/legal-entities/datagrid/settings?lcid=${userLanguage}`);
});

export const setPLEGridSettings = (settings: IPLEGridSettings) => action<Promise<void>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiPost(`/legal-entities/datagrid/settings?${userLanguage}`, settings);
});

export const deleteLegalEntityById = (legalEntityId: string) => action<Promise<void>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.profilesApiDelete(`/legal-entities/${legalEntityId}/permanent`);
});
