let version = 1;
let request: IDBOpenDBRequest;
let db: IDBDatabase;

export interface User {
    id: string;
    name: string;
    email: string;
}

export enum Stores {
    ALLREFERENTIALS = 'AllReferentials',
    ALLREFERENTIALSIDS = 'AllReferentialsIds'
}

export enum EDBNames {
    REFERENTIALSDB = 'referentialsDB'
}

export const initDB = (dbName: EDBNames): Promise<boolean> => {
    return new Promise((resolve) => {
        request = indexedDB.open(dbName, 2);

        request.onupgradeneeded = (ev: any) => {
            db = ev?.target?.result;

            if (!db.objectStoreNames.contains(Stores.ALLREFERENTIALS)) {
                db.createObjectStore(Stores.ALLREFERENTIALS);
            }
            if (!db.objectStoreNames.contains(Stores.ALLREFERENTIALSIDS)) {
                db.createObjectStore(Stores.ALLREFERENTIALSIDS);
            }
        };

        request.onsuccess = (ev: any) => {
            db = ev?.target?.result;
            version = db.version;
            resolve(true);
        };

        request.onerror = () => {
            resolve(false);
        };
    });
};

export const clearData = <T>(storeName: string, dbName: EDBNames): Promise<T | boolean | string | null> => {
    return new Promise((resolve) => {
        request = indexedDB.open(dbName, version);

        request.onsuccess = (ev: any) => {
            db = ev?.target?.result;
            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            store.clear();
            resolve(true);
        };

        request.onerror = () => {
            const error = request.error?.message;
            if (error) {
                resolve(error);
            } else {
                resolve('Unknown error');
            }
        };
    });
};

export const addData = <T>(storeName: string, data: T, key: string, dbName: EDBNames): Promise<T | string | null> => {
    return new Promise((resolve) => {
        request = indexedDB.open(dbName, version);

        request.onsuccess = (ev: any) => {
            db = ev?.target?.result;
            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            store.add(data, key);
            resolve(data);
        };

        request.onerror = () => {
            const error = request.error?.message;
            if (error) {
                resolve(error);
            } else {
                resolve('Unknown error');
            }
        };
    });
};

export const getStoreData = <T>(storeName: Stores, dbName: EDBNames): Promise<T[]> => {
    return new Promise((resolve) => {
        request = indexedDB.open(dbName);

        request.onsuccess = (ev: any) => {
            db = ev?.target?.result;
            const tx = db.transaction(storeName, 'readonly');
            const store = tx.objectStore(storeName);
            const res = store.getAll();
            res.onsuccess = () => {
                resolve(res.result);
            };
        };
    });
};

export const getStoreDataByKey = <T>(storeName: Stores, key: string): Promise<T> => {
    return new Promise((resolve) => {
        request = indexedDB.open('referentialsDB');

        request.onsuccess = (ev: any) => {
            db = ev?.target?.result;
            if (db.objectStoreNames.length === 0) {
                resolve(undefined);
            } else {
                const tx = db?.transaction(storeName, 'readonly');
                const store = tx?.objectStore(storeName);
                const res = store?.get(key);
                res.onsuccess = () => {
                    resolve(res.result);
                };
            }
        };
    });
};