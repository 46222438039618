import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { IState } from '../../../reducers';
import { IAvatarReducer } from '../../../reducers/avatarReducer';
import { setUserColor } from '../../../actions/avatarActions';
import { getEntityLink } from '../../../tools/clusterTools';
import { EProfileType } from '../../../entities/IGlobal';
import { ToolTip } from '../ToolTip/ToolTip';

type SetUserColor = ReturnType<typeof setUserColor>;

export enum EAvatarSize {
    XLARGE = 'XLARGE',
    LARGE = 'LARGE',
    MEDIUM = 'MEDIUM',
    SMALL = 'SMALL',
    XSMALL = 'XSMALL',
    TINY = 'TINY'
}

const Wrapper = styled.div<{ size: EAvatarSize }>`
    position: relative;
    max-width: 100%;
    a, span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
`;

const CurrentUserProfilePicture = styled.img<{ size: EAvatarSize, isSquare: boolean }>`
    border-radius: 100%;
    height: 32px;
    width: 32px;
    flex-shrink: 0;
    ${({ isSquare }) => isSquare && css`
        border-radius: 8px;
    `}
    ${({ size }) => size === EAvatarSize.TINY && css`
        height: 24px;
        width: 24px;
    `};
    ${({ size }) => size === EAvatarSize.SMALL && css`
        height: 40px;
        width: 40px;
    `};
    ${({ size }) => size === EAvatarSize.MEDIUM && css`
        height: 56px;
        width: 56px;
    `};
    ${({ size }) => size === EAvatarSize.LARGE && css`
        height: 96px;
        width: 96px;
    `};
    ${({ size }) => size === EAvatarSize.XLARGE && css`
        height: 160px;
        width: 160px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.TINY && isSquare) && css`
        border-radius: 4px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.SMALL && isSquare) && css`
        border-radius: 8px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.MEDIUM && isSquare) && css`
        border-radius: 16px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.LARGE && isSquare) && css`
        border-radius: 24px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.XLARGE && isSquare) && css`
        border-radius: 32px;
    `};
    background-color: ${`${colorStack.white}`};
`;

const TextWrapper = styled.div<{ bgColor: string; size?: EAvatarSize, isSquare: boolean }>`
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    border-radius: 100%;
    flex-shrink: 0;
    ${({ isSquare }) => isSquare && css`
        border-radius: 8px;
    `}
    ${props => props.bgColor && css`
        background: ${props.bgColor};
    `}
    ${({ size }) => size === EAvatarSize.TINY && css`
        height: 24px;
        width: 24px;
    `};
    ${({ size }) => size === EAvatarSize.SMALL && css`
        height: 40px;
        width: 40px;
    `};
    ${({ size }) => size === EAvatarSize.MEDIUM && css`
        height: 56px;
        width: 56px;
    `};
    ${({ size }) => size === EAvatarSize.LARGE && css`
        height: 96px;
        width: 96px;
    `};
    ${({ size }) => size === EAvatarSize.XLARGE && css`
        height: 160px;
        width: 160px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.TINY && isSquare) && css`
        border-radius: 4px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.SMALL && isSquare) && css`
        border-radius: 8px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.MEDIUM && isSquare) && css`
        border-radius: 16px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.LARGE && isSquare) && css`
        border-radius: 24px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.XLARGE && isSquare) && css`
        border-radius: 32px;
    `};
    > div {
        margin: auto 0;
        text-indent: 0;
        color: ${colorStack.content};
        font-weight: 500;
        ${fontSizeAndHeight[10]};
        ${({ size }) => size === EAvatarSize.TINY && css`
            ${fontSizeAndHeight[10]};
        `};
        ${({ size }) => size === EAvatarSize.SMALL && css`
            ${fontSizeAndHeight[13]};
        `};
        ${({ size }) => size === EAvatarSize.MEDIUM && css`
            ${fontSizeAndHeight[20]};
        `};
        ${({ size }) => size === EAvatarSize.LARGE && css`
            ${fontSizeAndHeight[39]};
        `};
        ${({ size }) => size === EAvatarSize.XLARGE && css`
            ${fontSizeAndHeight[61]};
        `};
    }
`;

const Name = styled.div<{ size: EAvatarSize }>`
    color: ${colorStack.content};
    margin: 0 0 0 .5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${fontSizeAndHeight[16]};
    ${({ size }) => size === EAvatarSize.TINY && css`
        ${fontSizeAndHeight[16]};
    `};
    ${({ size }) => size === EAvatarSize.SMALL && css`
        ${fontSizeAndHeight[16]};
    `};
    ${({ size }) => size === EAvatarSize.MEDIUM && css`
        ${fontSizeAndHeight[20]};
    `};
    ${({ size }) => size === EAvatarSize.LARGE && css`
        ${fontSizeAndHeight[39]};
    `};
    ${({ size }) => size === EAvatarSize.XLARGE && css`
        ${fontSizeAndHeight[61]};
    `};
    span {
        color: ${colorStack.content};
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const Shadow = styled.div<{ size: EAvatarSize, isSquare: boolean }>`
    height: 32px;
    width: 32px;
    position: absolute;
    border-radius: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0, .3);
    ${({ size }) => size === EAvatarSize.TINY && css`
        height: 24px;
        width: 24px;
    `};
    ${({ size }) => size === EAvatarSize.SMALL && css`
        height: 40px;
        width: 40px;
    `};
    ${({ size }) => size === EAvatarSize.MEDIUM && css`
        height: 56px;
        width: 56px;
    `};
    ${({ size }) => size === EAvatarSize.LARGE && css`
        height: 96px;
        width: 96px;
    `};
    ${({ size }) => size === EAvatarSize.XLARGE && css`
        height: 160px;
        width: 160px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.TINY && isSquare) && css`
        border-radius: 4px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.SMALL && isSquare) && css`
        border-radius: 8px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.MEDIUM && isSquare) && css`
        border-radius: 16px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.LARGE && isSquare) && css`
        border-radius: 24px;
    `};
    ${({ size, isSquare }) => (size === EAvatarSize.XLARGE && isSquare) && css`
        border-radius: 32px;
    `};
`;

interface IProps {
    picture: string;
    firstName: string;
    lastName: string;
    id: string;
    metaInfo?: string;
    disabled?: boolean;
    extended?: boolean;
    type?: EProfileType;
    size?: EAvatarSize;
    darkMode?: boolean;
    preventRedirection?: boolean;
    children?: React.ReactNode;
    noTooltip?: boolean;
}

const AvatarDetails: FC<React.PropsWithChildren<IProps>> = props => {
    const { usersColor } = useSelector<IState, IAvatarReducer>(state => state.avatar);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [coords, setCoords] = useState<number[]>([]);
    // tslint:disable-next-line:no-null-keyword
    const nameRef = useRef<HTMLDivElement>(null);

    const showTooltipHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        setCoords([e.pageX - nameRef?.current?.offsetWidth, e.pageY + 30]);
        setShowTooltip(true);
    };

    return (
        <>
            {props.picture ? (
                <ImageWrapper>
                    {props.darkMode &&
                        <Shadow
                            size={props.size || EAvatarSize.TINY}
                            isSquare={props.type === EProfileType.Company || props.type === EProfileType.Cluster || props.type === EProfileType.LegalEntity}
                        />
                    }
                    <CurrentUserProfilePicture
                        src={props.picture}
                        alt="User picture"
                        size={props.size || EAvatarSize.TINY}
                        isSquare={props.type === EProfileType.Company || props.type === EProfileType.Cluster || props.type === EProfileType.LegalEntity}
                    />
                </ImageWrapper>
            ) : (
                <TextWrapper
                    bgColor={usersColor?.[props.id]}
                    size={props.size || EAvatarSize.TINY}
                    isSquare={props.type === EProfileType.Company || props.type === EProfileType.Cluster || props.type === EProfileType.LegalEntity}
                >
                    <div>
                        {props.lastName?.length > 0 ? (
                            <>
                                {props.firstName?.slice(0, 1)}
                                {props.lastName?.slice(0, 1)}
                            </>
                        ) : (
                            <>
                                {props.firstName?.slice(0, 1)}
                                {props.firstName?.split(' ')?.[1]?.slice(0, 1)}
                            </>
                        )}
                    </div>
                </TextWrapper>
            )}
            {props.extended &&
                <Name size={props.size} onMouseMove={props.noTooltip ? undefined : showTooltipHandler} onMouseOver={props.noTooltip ? undefined : showTooltipHandler} onMouseLeave={() => setShowTooltip(false)} ref={nameRef}>
                    {props.metaInfo ? <strong>{props.firstName || ''} {props.lastName || ''}</strong> : `${props.firstName || ''} ${props.lastName || ''}`}
                    {props.metaInfo?.length > 0 && <><br />{props.metaInfo}</>}
                    {(showTooltip && (nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth)) &&
                        <ToolTip
                            title={`${props.firstName || ''} ${props.lastName || ''}`}
                            left={coords[0]}
                            top={coords[1]}
                        />
                    }
                </Name>
            }
        </>
    );
};

export const Avatar: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const { organization } = useParams<{ organization: string }>();
    const [targetType, setTargetType] = useState<string>(undefined);

    useEffect(() => {
        if (!props.picture) {
            dispatch<SetUserColor>(setUserColor(props.id));
        }
        setTargetType(IS_TEAMS_INSTANCE ? '_self' : '_blank');

    }, [IS_TEAMS_INSTANCE]);

    return (
        <Wrapper size={props.size}>
            {props.preventRedirection ? (
                <span>
                    <AvatarDetails {...props} />
                </span>
            ) : (
                <a href={getEntityLink(props?.id, organization, props?.type)} target={targetType}>
                    <AvatarDetails {...props} />
                </a>
            )}
        </Wrapper>
    );
};
